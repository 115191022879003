<script setup>
import TaskDataService from "@/services/TaskDataService";
import CommentDataService from "@/services/CommentDataService";
import WorklogDataService from "@/services/WorklogDataService";
import TaskStatusBadge from "@/components/Tasks/Task/TaskStatusBadge.vue";
import TaskHistoryDataService from "@/services/TaskHistoryDataService";
import UserDataService from "@/services/UserDataService";
import ContractDataService from "@/services/ContractDataService";
import { formatPriceInt, formatDate, formatDateTime } from "@/helpmate/utils";
import HelpmateAutocomplete from "@/helpmate/components/HelpmateAutocomplete.vue";
import moment from "moment";
</script>

<template>
  <NavBar :title="`${data.code} - ${data.title}`"
    :breadcrumbs="[{ 'url': { name: 'TaskListView', params: {} }, 'title': 'Задачи' }]">
  </NavBar>
  <div class="container">

    <h3 v-if="!isEditingTitle" @click="isEditingTitle = true" class="cru-editable">{{ data.title }} <i class="bi-pencil"></i></h3>
    <input v-else v-model="data.title" @keyup.enter.exact="saveTitle" @blur="saveTitle" class="form-control" />

    <div class="row">
      <div class="col-md-8">
        <h5>Детали задачи</h5>
        
        <div style="margin-bottom: 10px;">
          <span class="text-muted">Шифр:</span>
          <span v-if="!isEditingCode" @click="isEditingCode = true" class="cru-editable">{{ data.code }} <i class="bi-pencil"></i></span>
          <input v-else v-model="data.code" @keyup.enter.exact="saveCode" @blur="saveCode" class="form-control" />
        </div>


        <p><span class="text-muted">Статус:</span> <TaskStatusBadge :status="data.status" :status_text="data.status_text" /></p>

        <div class="btn-group" role="group" aria-label="Task Actions">
          <button v-if="data.available_transitions.includes(STATUS_NEW)" type="button" class="btn btn-light"
            @click="reopenTask"><i class="bi-arrow-clockwise"></i> Переоткрыть</button>
          <button v-if="data.available_transitions.includes(STATUS_IN_PROGRESS)" type="button" class="btn btn-light"
            @click="executeTask"><i class="bi-play"></i> Выполняю</button>
          <button v-if="data.available_transitions.includes(STATUS_COMPLETED)" type="button" class="btn btn-light"
            @click="completeTask"><i class="bi-check"></i> Выполнено</button>
          <button v-if="data.available_transitions.includes(STATUS_CANCELLED)" type="button" class="btn btn-light"
            @click="cancelTask"><i class="bi-x"></i> Отменить</button>
          <button v-if="data.available_transitions.includes(STATUS_CLOSED)" type="button" class="btn btn-light"
            @click="closeTask"><i class="bi-flag"></i> Закрыть</button>
          <button v-if="data.available_transitions.includes(STATUS_PAUSED)" type="button" class="btn btn-light"
            @click="pauseTask"><i class="bi-pause"></i> Приостановить</button>
        </div>

        <h5>Описание</h5>
        <div>
          <div v-if="!isEditingContent" @click="isEditingContent = true" class="cru-editable">{{ data.content || 'Описание отсутствует' }} <i class="bi-pencil"></i></div>
          <textarea v-else v-model="data.content" @keyup.enter.exact="saveContent" @blur="saveContent" class="form-control"></textarea>
        </div>

        <h5>Примечание</h5>
        <div>
          <div v-if="!isEditingComment" @click="isEditingComment = true" class="cru-editable">{{ data.content_comment || 'Примечание отсутствует' }} <i class="bi-pencil"></i></div>
          <textarea v-else v-model="data.content_comment" @keyup.enter.exact="saveComment" @blur="saveComment" class="form-control"></textarea>
        </div>

        <h5>Результат</h5>
        <div>
          <div v-if="!isEditingResult" @click="isEditingResult = true" class="cru-editable">{{ data.result || 'Результат отсутствует' }} <i class="bi-pencil"></i></div>
          <textarea v-else v-model="data.result" @keyup.enter.exact="saveResult" @blur="saveResult" class="form-control"></textarea>
        </div>

      </div>
      <div class="col-md-4">
        <h5>Люди</h5>
        <div style="margin-bottom: 10px;">
          <span class="text-muted">Исполнитель:</span> 
          <span v-if="!isEditingUserContractor" @click="isEditingUserContractor = true" class="cru-editable">{{ data.user_contractor_object?.email || 'Не назначен' }} <i class="bi-pencil"></i></span>
          <HelpmateAutocomplete
                v-else
                @onselect="handleFkUserContractorSelect"
                :dataService="UserDataService"
                :autofocus="true"
                :value="{id: data.user_contractor, title: data.user_contractor_object?.email}"
                :id="`autocomplete_user_contractor`"
            ></HelpmateAutocomplete>
        </div>
        <div style="margin-bottom: 10px;">
          <span class="text-muted">Автор:</span> 
          <span v-if="!isEditingUser" @click="isEditingUser = true" class="cru-editable">{{ data.user_object?.email || 'Не назначен' }} <i class="bi-pencil"></i></span>
          <HelpmateAutocomplete
                v-else
                @onselect="handleFkUserSelect"
                :dataService="UserDataService"
                :autofocus="true"
                :value="{id: data.user, title: data.user_object?.email}"
                :id="`autocomplete_user`"
            ></HelpmateAutocomplete>
        </div>

        <h5>Договор</h5>
        <div style="margin-bottom: 10px;">
          <span class="text-muted">Договор:</span> 
          <span v-if="!isEditingContract" @click="isEditingContract = true" class="cru-editable">{{ data.contract_object?.code || 'Не назначен' }} <i class="bi-pencil"></i></span>
          <HelpmateAutocomplete
                v-else
                @onselect="handleFkContractSelect"
                :dataService="ContractDataService"
                :autofocus="true"
                :value="{id: data.contract, title: data.contract?.code}"
                :id="`autocomplete_contract`"
            ></HelpmateAutocomplete>
        </div>

        <h5>Даты</h5>
        <p><span class="text-muted">Создано:</span> {{ formatDate(data.created_at) }}</p>
        <p><span class="text-muted">Обновлено:</span> {{ formatDate(data.updated_at) }}</p>
        <div>
          <span class="text-muted">Дата исполнителя:</span>
          <span v-if="!isEditingDate" @click="isEditingDate = true" class="cru-editable" :class="{'task-field-red': data.date_at && new Date(data.date_at) < new Date()}">{{ formatDate(data.date_at) }} <i class="bi-pencil"></i></span>
          <input v-else v-model="data.date_at" @keyup.enter.exact="saveDate" @blur="saveDate" class="form-control" type="date" />
        </div>

        <h5>Время</h5>
        <p>
          <span class="text-muted">Оценка времени, ч:</span>&nbsp;
          <span v-if="!isEditingEstimatedAmount" @click="isEditingEstimatedAmount = true" class="cru-editable">{{ data.estimated_amount }} <i class="bi-pencil"></i></span>
          <input v-else v-model="data.estimated_amount" @keyup.enter.exact="saveEstimatedAmount" @blur="saveEstimatedAmount" class="form-control" type="number" />
        </p>
        <p><span class="text-muted">Фактически затрачено, ч:</span> <span :class="{'task-field-red': data.time_spent && data.time_spent > data.estimated_amount}">{{ data.time_spent }}</span></p>

        <h5>Стоимость</h5>
        <p><span class="text-muted">Стоимость по договору, руб.:</span> {{ formatPriceInt(data.contract_amount) }}</p>
        <p><span class="text-muted">Фактическая стоимость, руб.:</span> <span :class="{'task-field-red': data.actual_amount && data.actual_amount > data.contract_amount}">{{ formatPriceInt(data.actual_amount) }}</span></p>
        <p><span class="text-muted">Стоимость трудозатрат, руб.:</span> {{ formatPriceInt(data.cost_amount) }}</p>
      </div>
    </div>

    <HelpmateTabs>
      <HelpmateTab :title="`Комментарии (${comments.length})`" active="true">


        <div v-for="comment in comments" :key="comment.id">
          <div><span class="text-muted">{{ comment.user_object.first_name }} {{ comment.user_object.last_name }} - {{
            formatDateTime(comment.created_at) }}</span></div>
          <div class="bg-light" v-html="comment.content"></div>
        </div>

        <form @submit.prevent="submitComment">
          <ckeditor v-model="editorData" :editor="editor" :config="editorConfig" />
          <button class="btn btn-primary mt-1" type="submit">Добавить</button>
        </form>
      </HelpmateTab>
      <HelpmateTab title="Журнал работ">

        <form class="mt-3" @submit.prevent="submitWorklog">
          <div class="form-group">
            <label for="worklogTime">Затраченное время, часы</label>
            <input type="number" class="form-control" v-model="worklogTime" placeholder="Затраченное время, часы" />
          </div>
          <div class="form-group">
            <label for="worklogDate">Дата затраченного времени</label>
            <input type="date" class="form-control" v-model="worklogDate" placeholder="Дата" />
          </div>
          <button class="btn btn-primary mt-1" type="submit">Добавить</button>
        </form>

        <div class="mt-3" v-for="worklog in worklogs" :key="worklog.id">
          <div><span class="text-muted">{{ worklog.user_object.first_name }} {{ worklog.user_object.last_name }} - {{
            formatDateTime(worklog.created_at) }}</span></div>
          <div class="bg-light">{{ formatDate(worklog.date_at) }} &rarr; {{ worklog.time_spent }} ч</div>
        </div>


      </HelpmateTab>
      <HelpmateTab title="История изменений">
        <div v-for="history in histories" :key="history.id">
          <div v-if="history.changes.length > 0" class="bg-light mt-2">
            <div><span class="text-muted">{{ history.history_user.first_name }} {{ history.history_user.last_name }} - {{ formatDateTime(history.history_date) }}</span></div>
            <div style="margin-bottom: 10px;" v-for="change in history.changes" :key="change.field">
              {{ change.field }}: {{ change.old }} &rarr; {{ change.new }}
            </div>
          </div>
        </div>
      </HelpmateTab>
    </HelpmateTabs>


  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
// import {Ckeditor} from '@ckeditor/ckeditor5-vue';
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";
import { format } from 'date-fns';
import { STATUS_NEW, STATUS_IN_PROGRESS, STATUS_COMPLETED, STATUS_CLOSED, STATUS_PAUSED, STATUS_CANCELLED } from '@/constants';
export default {
  name: 'TaskDetailView',
  components: {
    NavBar,
    // Ckeditor,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
      },
      histories: [],
      worklogTime: 8,
      worklogDate: moment().format('YYYY-MM-DD'),
      isEditingEstimatedAmount: false,
      isEditingResult: false,
      isEditingComment: false,
      isEditingContent: false,
      isEditingCode: false,
      isEditingTitle: false,
      isEditingDate: false,
      isEditingUserContractor: false,
      isEditingUser: false,
      isEditingContract: false,
      data: {
        code: '',  // Шифр Договора (титула)
        title: '',  // Наименование Договора (титула)
        user: null,  // Исполнитель
        content: '',  // Содержание задачи
        status: 0,
        status_text: '',
        date_at: '',
        created_at: null,
        updated_at: null,
        user_contractor: null,
        contract: null,
        available_transitions: [],
        contract_amount: 0,
        actual_amount: 0,
        cost_amount: 0,
        estimated_amount: 0,
        contract_object: null,
      },
      comments: [],
      worklogs: [],
    };
  },
  methods: {
    updateHistories() {
      TaskHistoryDataService.getList({ id: this.taskId })
        .then((response) => {
          this.histories = response.data.results
        })
        .catch(e => {
          console.log(e);
        });
    },
    handleFkContractSelect(option) {
      this.data.contract = option.id
      this.isEditingContract = false
      TaskDataService.update(this.data.id, { contract: option.id })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    handleFkUserContractorSelect(option) {
      this.data.user_contractor = option.id
      this.isEditingUserContractor = false
      TaskDataService.update(this.data.id, { user_contractor: option.id })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    handleFkUserSelect(option) {
      this.data.user = option.id
      this.isEditingUser = false
      TaskDataService.update(this.data.id, { user: option.id })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveDate() {
      this.isEditingDate = false
      TaskDataService.update(this.data.id, { date_at: this.data.date_at })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveCode() {
      this.isEditingCode = false
      TaskDataService.update(this.data.id, { code: this.data.code })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveTitle() {
      this.isEditingTitle = false
      TaskDataService.update(this.data.id, { title: this.data.title })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveEstimatedAmount() {
      this.isEditingEstimatedAmount = false
      TaskDataService.update(this.data.id, { estimated_amount: this.data.estimated_amount })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveResult() {
      this.isEditingResult = false
      TaskDataService.update(this.data.id, { result: this.data.result })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveContent() {
      this.isEditingContent = false
      TaskDataService.update(this.data.id, { content: this.data.content })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    saveComment() {
      this.isEditingComment = false
      TaskDataService.update(this.data.id, { content_comment: this.data.content_comment })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
        });
    },
    submitWorklog() {
      WorklogDataService.create({
        task: this.taskId,
        time_spent: this.worklogTime,
        date_at: this.worklogDate
      })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          console.log(e);
        });
    },
    submitComment() {
      CommentDataService.create({
        task: this.taskId,
        content: this.editorData
      })
        .then(() => {
          this.editorData = ''
          this.updateComments()
        })
        .catch(e => {
          console.log(e);
        });
    },
    reopenTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_NEW).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    executeTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_IN_PROGRESS).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    completeTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_COMPLETED).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    cancelTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_CANCELLED).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    closeTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_CLOSED).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    pauseTask() {
      TaskDataService.updateStatus(this.data.id, STATUS_PAUSED).then(() => {
        this.updateData()
      }).catch(e => {
        this.$store.commit('addToast', {title: "Ошибка", message: e.response.data.error})
      });
    },
    formatDate(dateString) {
      if (!dateString) return '';
      return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
    },
    updateComments() {
      CommentDataService.getList({ task_id: this.taskId })
        .then((response) => {
          this.comments = response.data.results
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateWorklogs() {
      WorklogDataService.getList({ task_id: this.taskId })
        .then((response) => {
          this.worklogs = response.data.results
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      TaskDataService.getById(this.taskId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          // this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          // Object.keys(this.data).forEach((key) => {
          //   this.data[key] = formatPriceInt(this.data[key]);
          // });
          this.updateComments()
          this.updateWorklogs()
          this.updateHistories()
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  computed: {
    taskId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.updateData()
  },
};
</script>

<style>
.ck-editor__editable_inline {
  min-height: 100px;
}
.task-field-red {
  color: white;
  background-color: red !important;
  padding: 2px;
  border-radius: 3px;
}
</style>
