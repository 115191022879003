<script setup>
import UserDataService from "@/services/UserDataService";
import HelpmateAutocomplete from "@/helpmate/components/HelpmateAutocomplete.vue";
</script>

<template>
  <NavBar :title="`Договор ${data.code}`"
    :breadcrumbs="[{ 'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры' }]">

  </NavBar>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h2>Договор</h2>
        <form @submit.prevent="updateContract">
          <div class="form-group">
            <label for="contractCode">Шифр Договора (титула)</label>
            <input type="text" v-model="data.code" class="form-control" id="contractCode">
          </div>
          <div class="form-group">
            <label for="contractTitle">Наименование Договора (титула)</label>
            <input type="text" v-model="data.title" class="form-control" id="contractTitle">
          </div>
          <div class="form-group">
            <label for="contractAmount">Стоимость Договора</label>
            <input type="number" v-model="data.amount" class="form-control" id="contractAmount">
          </div>

          <div class="form-group">
            <label for="contractUser">Руководитель проекта</label>
            <HelpmateAutocomplete
                :autofocus="true"
                 @onselect="handleUserSelect"
                :id="`contractUser`"
                :value="data.user_autocomplete_object"
                :dataService="UserDataService"
            ></HelpmateAutocomplete>
          </div>
          <div class="form-group">
            <label for="contractEndAt">Дата окончания</label>
            <input type="date" v-model="data.end_at" class="form-control" id="contractEndAt">
          </div>

          <div class="mt-3">
            <button class="btn btn-sm btn-light" @click="contractSettingsExpanded = !contractSettingsExpanded">
              <span v-if="contractSettingsExpanded">Свернуть настройки этого договора</span>
              <span v-else>Развернуть настройки этого договора</span>
            </button>
          </div>

          <div v-if="contractSettingsExpanded">
            <div class="form-group">
              <label for="contractInheritNalogFond"><input type="checkbox" v-model="data.inherit_nalog_fond" id="contractInheritNalogFond"> Наследовать взносы в социальные фонды из настроек</label>
            </div>
            <div class="form-group" v-if="!data.inherit_nalog_fond">
              <label for="contractNalogFond">Взносы в социальные фонды (%) <input step="any" type="number" class="form-control" v-model="data.nalog_fond" id="contractNalogFond"></label>
            </div>
            <div class="form-group">
              <label for="contractInheritNalogTravma"><input type="checkbox" v-model="data.inherit_nalog_travma" id="contractInheritNalogTravma"> Наследовать страховые взносы на обязательное социальное страхование от несчастных случаев на производстве из настроек</label>
            </div>
            <div class="form-group" v-if="!data.inherit_nalog_travma">
              <label for="contractNalogTravma">Страховые взносы на обязательное социальное страхование от несчастных случаев на производстве (%) <input step="any" type="number" class="form-control" v-model="data.nalog_travma" id="contractNalogTravma"></label>
            </div>
            <div class="form-group">
              <label for="contractInheritKoefNakl"><input type="checkbox" v-model="data.inherit_koef_nakl" id="contractInheritKoefNakl"> Наследовать коэффициент накладных расходов из настроек</label>
            </div>
            <div class="form-group" v-if="!data.inherit_koef_nakl">
              <label for="contractKoefNakl">Коэффициент накладных расходов (%) <input step="any" type="number" class="form-control" v-model="data.koef_nakl" id="contractKoefNakl"></label>
            </div>
            <div class="form-group">
              <label for="contractInheritKoefProfit"><input type="checkbox" v-model="data.inherit_koef_profit" id="contractInheritKoefProfit"> Наследовать коэффициент прибыли из настроек</label>
            </div>
            <div class="form-group" v-if="!data.inherit_koef_profit">
              <label for="contractKoefProfit">Коэффициент прибыли (%) <input step="any" type="number" class="form-control" v-model="data.koef_profit" id="contractKoefProfit"></label>
            </div>
          </div>

          <button type="submit" class="btn btn-primary mt-3">Сохранить</button>
          &nbsp;
          <RouterLink class="btn btn-secondary mt-3" :to="`/contracts/${contractId}/tasks`">Задачи
            договора &rarr;</RouterLink>
        </form>
      </div>
      <div class="col-md-8">
        <div class="bg-light p-3 mt-3">
          <HelpmateTabs>
            <HelpmateTab title="Основные данные" active="true">
              <ContractTotalBase :contractId="contractId"></ContractTotalBase>
            </HelpmateTab>
            <HelpmateTab title="Расчет фактической стоимости">
              <p>Трудозатраты с налогами, без накладных и прибыли</p>
              <ContractTotalFact :contractId="contractId"></ContractTotalFact>
            </HelpmateTab>
            <HelpmateTab title="Расчет требуемой стоимости">
              <p>Трудозатраты с налогами, накладными и прибылью</p>
              <ContractTotalTreb :contractId="contractId"></ContractTotalTreb>
            </HelpmateTab>
          </HelpmateTabs>
        </div>
      </div>
    </div>





    <!-- <h1>Части договора</h1> -->

    <h5>Доп. соглашения по договору</h5>

    <SupplementaryAgreementListComponent :contractId="contractId"></SupplementaryAgreementListComponent>


    <h5>Субподряды</h5>

    <SubcontractListComponent :contractId="contractId"></SubcontractListComponent>

    <h5>Дополнительные расходы</h5>

    <AdditionalCostListComponent :contractId="contractId"></AdditionalCostListComponent>

    <h5>Командировочные расходы</h5>

    <TravelExpenseListComponent :contractId="contractId"></TravelExpenseListComponent>

    <h5>Акты</h5>

    <ActListComponent :contractId="contractId"></ActListComponent>

    <h5>Платежи</h5>

    <PaymentListComponent :contractId="contractId"></PaymentListComponent>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SupplementaryAgreementListComponent
  from "@/components/LegalDocuments/SupplementaryAgreement/SupplementaryAgreementListComponent.vue";
import SubcontractListComponent from "@/components/LegalDocuments/Subcontract/SubcontractListComponent.vue";
import AdditionalCostListComponent from "@/components/LegalDocuments/AdditionalCost/AdditionalCostListComponent.vue";
import TravelExpenseListComponent from "@/components/TravelExpense/TravelExpenseListComponent.vue";
import ActListComponent from "@/components/LegalDocuments/Act/ActListComponent.vue";
import PaymentListComponent from "@/components/LegalDocuments/Payment/PaymentListComponent.vue";
import ContractTotalFact from "@/components/ContractTotal/ContractTotalFact.vue";
import ContractTotalTreb from "@/components/ContractTotal/ContractTotalTreb.vue";
import ContractTotalBase from "@/components/ContractTotal/ContractTotalBase.vue";
import ContractDataService from "@/services/ContractDataService";
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";

export default {
  name: 'ContractDetailView',
  components: {
    ContractTotalTreb,
    ContractTotalFact,
    ContractTotalBase,
    PaymentListComponent,
    ActListComponent,
    TravelExpenseListComponent,
    AdditionalCostListComponent, SubcontractListComponent, SupplementaryAgreementListComponent, NavBar,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      isLoading: true,
      contractSettingsExpanded: false,
      data: {
        code: '',  // Шифр Договора (титула)
        title: '',  // Наименование Договора (титула)
        amount: 0,  // Стоимость Договора
        user: null,  // Руководитель проекта
        end_at: null,  // Дата окончания
        user_autocomplete_object: { id: null, title: '' },
        inherit_nalog_fond: true,
        inherit_nalog_travma: true,
        inherit_koef_nakl: true,
        inherit_koef_profit: true,
        nalog_fond: 0,
        nalog_travma: 0,
        koef_nakl: 0,
        koef_profit: 0,
      }
    }
  },
  methods: {
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      ContractDataService.getById(this.contractId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          // Object.keys(this.data).forEach((key) => {
          //   this.data[key] = formatPriceInt(this.data[key]);
          // });
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateContract() {
      ContractDataService.patch(this.contractId, {
        code: this.data.code,
        title: this.data.title,
        amount: this.data.amount,
        user: this.data.user,
        user_object: this.data.user_object,
        end_at: this.data.end_at,
        inherit_nalog_fond: this.data.inherit_nalog_fond,
        inherit_nalog_travma: this.data.inherit_nalog_travma,
        inherit_koef_nakl: this.data.inherit_koef_nakl,
        inherit_koef_profit: this.data.inherit_koef_profit,
        nalog_fond: this.data.nalog_fond,
        nalog_travma: this.data.nalog_travma,
        koef_nakl: this.data.koef_nakl,
        koef_profit: this.data.koef_profit,
      })
        .then(response => {
          // console.log(response.data)
          this.data = response.data
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          this.$store.commit('addToast', { title: "Сохранено", message: "Данные договора сохранены" })
        })
        .catch(e => {
          console.log(e);
          this.$store.commit('addToast', { title: "Ошибка!", message: "Не сохранено" })
        });
    },
    handleUserSelect(option) {
      this.data.user = option.id
    }
  },
  computed: {
    contractId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.updateData()
  },
};
</script>
