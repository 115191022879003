<template>
  <div class="input-group">
    <input type="text" class="form-control" v-model="searchQuery" @input="handleInputChange" @keyup.esc="closeDropdown()" ref="autocompleteInput">
    <ul class="dropdown-menu" :class="{ show: isDropdownVisible }" style="margin-top: 40px;">
      <li v-for="(option) in options" :key="option.id" @click.prevent="selectOption(option)" class="dropdown-item" :class="{ active: selectedOption && selectedOption.id === option.id }" style="cursor: default;">
        {{ option.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelpmateAutocomplete',
  props: {
    dataService: { // Добавлено свойство dataService
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default: () => ({ id: null, title: '' })
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: this.value.title,
      selectedOption: this.value,
      isDropdownVisible: false,
      options: [] // Инициализация массива options
    }
  },
  watch: {
    searchQuery(newQuery) {
      if (newQuery === this.value.title) {
        return;
      }
      this.isDropdownVisible = newQuery.length > 0; // показываем дропдаун, если есть ввод
      if (newQuery.length > 0) {
        this.fetchOptions(newQuery); // Получаем опции при вводе
      }
    },
    value(newValue) {
      this.selectedOption = newValue;
      this.searchQuery = newValue.title;
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.autocompleteInput.focus();
    }
  },
  methods: {
    closeDropdown() {
      console.log('closeDropdown')
      console.log(this.selectedOption)
      this.selectOption(this.selectedOption)
    },
    handleInputChange(event) {
      this.searchQuery = event.target.value;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('onselect', option);
      this.searchQuery = option.title;
      setTimeout(() => {
        this.isDropdownVisible = false;
      }, 1);
    },
    async fetchOptions(query) {
      try {
        const response = await this.dataService.autocomplete(query); // Используем dataService для получения опций
        this.options = response.data; // Обновляем options с полученными данными
      } catch (error) {
        console.error('Ошибка при получении опций:', error);
      }
    }
  }
}
</script>