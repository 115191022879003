<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 280px; height: 100vh;">
    <RouterLink class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none" to="/">
      <span class="fs-4">Helpmate АЭСП</span>
      <sup style="color: #8e8e8e; text-align: right; margin-left: 10px;">1.0.0</sup>
    </RouterLink>
    <hr>
    <div class="scrollarea-sidebar  mb-auto">
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/">
            <i class="bi bi-speedometer2"></i> Дашборд
          </RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/contracts">
            <i class="bi bi-folder"></i> Договоры
          </RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/users">
            <i class="bi bi-people"></i> Сотрудники
          </RouterLink>
        </li>
<!--        <li class="nav-item">-->
<!--          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/staffs/timeline">-->
<!--            Загрузка сотрудников-->
<!--          </RouterLink>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/acts">Акты</RouterLink>-->
<!--        </li>-->
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/tasks">
            <i class="bi bi-journal-check"></i> Задачи
          </RouterLink>
        </li>
       <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/staffs/org-structure">
            <i class="bi bi-diagram-2"></i> Орг. структура
          </RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/stats/contracts">
            <i class="bi bi-graph-up"></i> Статистика
          </RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink class="nav-link text-white" activeClass="active" aria-current="page" to="/settings">
            <i class="bi bi-gear"></i> Настройки
          </RouterLink>
        </li>
      </ul>
    </div>
    <hr>
    <div class="dropdown text-center">
      <a href="#" class="d-flex justify-content-center align-items-center text-white text-decoration-none dropdown-toggle"
         data-bs-toggle="dropdown" aria-expanded="false">
        <strong><i class="bi bi-person"></i> {{userTitle}}</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
        <li>
          <RouterLink class="dropdown-item" activeClass="active" aria-current="page" to="/profile">
            Профиль
          </RouterLink>
        </li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" href="/admin/">Административный раздел</a></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li>
          <RouterLink class="dropdown-item" activeClass="active" aria-current="page" to="/logout">
            Выйти
          </RouterLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  computed: {
    userTitle() {
      return `${this.$store.state.profile.first_name} ${this.$store.state.profile.last_name}`
    }
  }
};
</script>
