<template>

  <HelpmateTable :tableContext="tableContext" :dataService="getDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";
import ActDataService from "@/services/ActDataService";

export default {
  name: 'ActListComponent',
  components: {
    HelpmateTable,
  },
  props: ['contractId'],
  data() {
    return {
      tableContext: {
        contractId: 0,
        baseFilter: { // для обязательной фильтрации таблицы
          contract_id: this.contractId,
        }
      },
      columns: [
        // {field: 'id', fieldType: 'link', title: 'Подробнее', linkTemplate: '/contract/detail/#ID#', readonly: true},
        {field: 'code', fieldType: 'text', title: 'Номер акта'},
        {field: 'title', fieldType: 'text', title: 'Назначение акта'},
        {field: 'sets_amount', fieldType: 'priceint', title: 'Кол-во комплектов'},
        {field: 'amount', fieldType: 'priceint', title: 'Стоимость вып-ных раб., вкл. НДС, руб.', aggregate: 'sum'},
        {field: 'advance_computed', fieldType: 'priceint', title: 'Зачет аванса от ст-ти вып. раб., вкл. НДС', readonly: true, aggregate: 'sum'},
        {field: 'percent_advance', fieldType: 'price', title: '% аванса'},
        {field: 'payment_computed', fieldType: 'price', title: 'К оплате с НДС, руб.', readonly: true, aggregate: 'sum'},
        {field: 'percent_amount_computed', fieldType: 'price', title: '% от ст-ти вып. работ', readonly: true},
        {field: 'retention_amount_computed', fieldType: 'price', title: 'Удерж. от ст-ти вып. раб. с НДС, руб.', readonly: true, aggregate: 'sum'},
        {field: 'percent_retention', fieldType: 'price', title: '% удержания'},
        {field: 'comment', fieldType: 'text', title: 'Примечание'},
        {field: 'date_at', fieldType: 'date', title: 'Дата акта'},
      ],
    }
  },
  methods: {
    getDataService() {
      return ActDataService
    },
  },
  mounted() {
    this.tableContext.contractId = this.contractId
  }
};
</script>
