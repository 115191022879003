import AbstractDataService from "@/helpmate/services/AbstractDataService";

class TaskHistoryDataService extends AbstractDataService {

    constructor() {
        super('/tasks/task_history');
    }
}

export default new TaskHistoryDataService();
