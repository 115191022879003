<script setup>
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";
import HelpmateAutocomplete from "@/helpmate/components/HelpmateAutocomplete.vue";
import TaskStatusBadge from "@/components/Tasks/Task/TaskStatusBadge.vue";
import _ from 'lodash'

</script>

<template>

  <HelpmateSpinner :is-loading="isLoading"></HelpmateSpinner>

  <div v-if="!isLoading">

    <div class="spreadsheet table-responsive">
      <!-- создаем таблицу -->
      <table
          class="helpmate-table table table-hover table-bordered"
          ref="controlTable"
          tabindex="0"
          @keyup.space="editing ? null : toggleSelected()"
          @keydown.left="editing ? null : moveLeft()"
          @keydown.right="editing ? null : moveRight()"
          @keydown.up="editing ? null : moveUp()"
          @keydown.down="editing ? null : moveDown()"
          @keyup.shift.ctrl.enter="editing ? null : cloneCurrentRow()"
          @keyup.exact.ctrl.enter="editing ? null : addRow()"
          @keyup.exact.enter.prevent.stop="toggleEdit()"
          @keyup.exact.delete="editing ? null : deleteRows()"

      >
        <thead>
        <tr>
          <th style="width: 16px;"><input type="checkbox" v-model="selectAll" @change="toggleSelectAll"></th>
          <th style="width: 16px;">№</th>
          <th v-for="(column, columnIndex) in columns" :key="columnIndex" :class="{'text-muted': column.readonly}" @click="sortColumnChange(column)">
            {{ column.title }}
            <span v-if="sortColumn === column.field" :class="sortDirection === 'asc' ? 'bi-sort-down-alt' : 'bi-sort-down'"></span>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th v-for="(column, columnIndex) in columns" :key="columnIndex">
            <input v-if="!column.readonly" type="text" v-model="localFilters[column.field]" class="form-control cru-form-control-small" placeholder="Фильтр">
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(obj, rowIndex) in objects" :key="rowIndex" :class="{'selected-row': obj.selected}">
          <td><input type="checkbox" v-model="obj.selected"></td>
          <td>{{ rowIndex + 1 }}</td>
          <td v-for="(cell, cellIndex) in obj.values" :key="cellIndex"
              @click="selectCell(rowIndex, cellIndex)"
              @dblclick.prevent.stop="toggleEdit()"
              :class="{'selected-cell': cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]}">

            <input v-model="inputText" :id="`helpmate_cell_input_${rowIndex}_${cellIndex}`" type="text"
                   v-if="editing && columns[selectedCellIndex[1]].fieldType === 'text' && cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]"
                   class="form-control"
            >
            <input v-model="inputText" :id="`helpmate_cell_input_${rowIndex}_${cellIndex}`" type="number"
                   v-else-if="editing && (columns[selectedCellIndex[1]].fieldType === 'priceint' || columns[selectedCellIndex[1]].fieldType === 'price') && cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]"
                   class="form-control"
            >
            <input v-model="inputText" :id="`helpmate_cell_input_${rowIndex}_${cellIndex}`" type="date"
                   v-else-if="editing && columns[selectedCellIndex[1]].fieldType === 'date' && cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]"
                   class="form-control"
            >
            <HelpmateAutocomplete
                @onselect="handleFkSelect"
                :dataService="columns[selectedCellIndex[1]].dataService"
                :autofocus="true"
                :id="`helpmate_cell_input_${rowIndex}_${cellIndex}`"
                v-else-if="editing && columns[selectedCellIndex[1]].fieldType === 'fk' && cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]"
            ></HelpmateAutocomplete>
            <RouterLink :to="cell.linkData"
            v-else-if="columns[cellIndex].fieldType === 'link'"
            >{{ cell.formattedValue }}</RouterLink>

            <TaskStatusBadge :status="cell.value" :status_text="cell.formattedValue" v-else-if="columns[cellIndex].fieldType === 'status'" />

            <span v-else :class="cell.cssClasses" :title="cell.titleValue">{{ cell.formattedValue }}</span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th colspan="2">Итого</th>
          <th v-for="(column, columnIndex) in footerColumns" :key="columnIndex">
            <span :class="column.cssClasses">{{ column.aggregatedValue }}</span>
          </th>
        </tr>
        </tfoot>
      </table>

      <nav aria-label="Page navigation" class="mt-4" v-if="totalPages > 1">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: Number(page) === 1 }">
            <a class="page-link" href="#" @click.prevent="handlePageClick(Number(page) - 1)" aria-label="Previous">
              <span aria-hidden="true">&larr;</span>
            </a>
          </li>
          <li v-if="Math.min(...navPages) > 1" class="page-item">
            <span class="page-link">...</span>
          </li>
          <li v-for="n in navPages" :key="n" class="page-item" :class="{ active: n === Number(page) }">
            <a class="page-link" href="#" @click.prevent="handlePageClick(n)">{{ n }}</a>
          </li>
          <li v-if="totalPages > Math.max(...navPages)" class="page-item">
            <span class="page-link">...</span>
          </li>
          <li class="page-item" :class="{ disabled: Number(page) === totalPages }">
            <a class="page-link" href="#" @click.prevent="handlePageClick(Number(page) + 1)" aria-label="Next">
              <span aria-hidden="true">&rarr;</span>
            </a>
          </li>
        </ul>
      </nav>

      <div class="btn-group" role="group">
        <button @click="addRow()" class="btn btn-light">Добавить<br/><span
            class="helpmate-btn-hotkey">Ctrl + Enter</span></button>
        <button @click="cloneCurrentRow()" class="btn btn-light">Клонировать<br/><span class="helpmate-btn-hotkey">Ctrl + Shift + Enter</span>
        </button>
        <button @click="deleteRows()" class="btn btn-light">Удалить выделенные строки<br/><span
            class="helpmate-btn-hotkey">Delete</span></button>

        <div class="helpmate-table-help" style="margin-left: 10px;">
          Для перемещения по ячейкам и таблице используйте стрелки или курсор мыши.<br/>
          Для редактирования ячейки используйте Enter или двойной клик мыши.<br/>
          Для выделения строки используйте пробел или флажок слева.<br/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import formatField from "./HelpmateTableCommon/formatField";

export default {
  name: 'HelpmateTable',
  // props: ['dataService', 'columns', 'tableContext'], // tableContext.baseFilter
  props: {
    dataService: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    tableContext: {
      type: Object,
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  components: [
    HelpmateSpinner
  ],
  data() {
    return {
      isLoading: true,
      footerColumns: [],
      objects: [],
      selectedCellIndex: [0, 0], // индекс выделенной ячейки
      selectAll: false,
      editing: false,
      inputText: '',
      inputFk: null,
      sortColumn: null,
      sortDirection: 'asc',
      localFilters: {},
      page: 1,
      pageSize: 20,
      totalPages: 0,
      totalCount: 0,
      aggregates: {},
    }
  },
  methods: {
    handlePageClick(page) {
      this.page = page
      this.getAll()
    },
    sortColumnChange(column) {
      if (column.readonly) return;

      this.sortColumn = column.field
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      this.getAll()
    },
    moveLeft() {
      if (this.objects.length === 0) return;

      if (this.selectedCellIndex[1] > 0) {
        this.selectedCellIndex[1]--;
      }
    },
    moveRight() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];
      const colIndex = this.selectedCellIndex[1];

      if (colIndex < this.objects[rowIndex].values.length - 1) {
        this.selectedCellIndex[1]++;
      }
    },
    moveUp() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];

      if (rowIndex > 0) {
        this.selectedCellIndex[0]--;
      }
    },
    moveDown() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];

      if (rowIndex < this.objects.length - 1) {
        this.selectedCellIndex[0]++;
      }
    },
    toggleSelected() {
      const rowIndex = this.selectedCellIndex[0];
      this.objects[rowIndex].selected = !this.objects[rowIndex].selected;
    },
    toggleEdit() {
      const rowIndex = this.selectedCellIndex[0];
      const colIndex = this.selectedCellIndex[1];

      if (this.columns[colIndex].fieldType === 'link') {
        this.$router.push(this.objects[rowIndex].values[colIndex].linkData);
        return;
      }

      if (this.columns[colIndex].readonly) {
        this.$store.commit('addToast', {
          title: "Изменение недоступно",
          message: "Данные в этом столбце доступны только для чтения."
        })
        return;
      }

      if (this.editing) {
        const value = this.columns[colIndex].fieldType === 'fk' ? this.inputFk : this.inputText;
        const {
          formattedValue,
          titleValue,
          cssClasses,
        } = formatField(value, this.columns[colIndex], this.objects[rowIndex])
        // todo: refactor this
        this.saveCell(rowIndex, colIndex, value)

        this.objects[rowIndex].values[colIndex] = {
          value: value,
          formattedValue: formattedValue,
          titleValue: titleValue,
          cssClasses: cssClasses,
        }
      } else {
        if (this.columns[colIndex].fieldType === 'fk') {
          this.inputFk = this.objects[rowIndex].values[colIndex].value ? {
            id: this.objects[rowIndex].values[colIndex].value.id,
            title: this.objects[rowIndex].values[colIndex].value[this.columns[colIndex].fkFieldTitle]
          } : null;
        } else {
          this.inputText = this.objects[rowIndex].values[colIndex].value;
        }
      }

      this.editing = !this.editing;

      if (this.editing) {
        const rowIndex = this.selectedCellIndex[0];
        const colIndex = this.selectedCellIndex[1];
        setTimeout(() => {
          document.getElementById(`helpmate_cell_input_${rowIndex}_${colIndex}`).focus()
        }, 1)
      } else {
        const controlTable = this.$refs.controlTable;
        if (controlTable) {
          controlTable.focus();
        }
      }
    },
    saveCell(rowIndex, cellIndex, newValue) {
      const id = this.objects[rowIndex].id
      const fieldName = this.columns[cellIndex].field
      let data = {}

      if (this.columns[cellIndex].fieldType === 'fk') {
        data[this.columns[cellIndex].fkSaveField] = newValue.id; // Сохраняем id для fkSaveField
      } else {
        data[fieldName] = newValue;
      }

      this.dataService.patch(id, data)
          .then(response => {
            console.log(response)
            this.getAll([rowIndex, cellIndex])
          })
          .catch(e => {
            console.log(e);
          });
    },
    selectCell(rowIndex, cellIndex) {
      if (this.editing) {
        return;
        // this.toggleEdit()  // если редактируем, то прекращаем
      }
      this.selectedCellIndex = [rowIndex, cellIndex];
    },
    toggleSelectAll() {
      this.objects.forEach(item => {
        item.selected = this.selectAll;
      });
    },
    addRow() {
      // todo: add row
      this.dataService.createEmpty(this.tableContext)
          .then(response => {
            console.log(response)
            const item = response.data
            //
            let itemArr = []
            this.columns.forEach(column => {
              const value = item[column.field]
              const {
                formattedValue,
                titleValue,
                cssClasses,
                linkData,
              } = formatField(value, column, item)

              itemArr.push({
                value: value,
                formattedValue: formattedValue,
                titleValue: titleValue,
                cssClasses: cssClasses,
                linkData: linkData,
              })
            });

            this.objects.push({values: itemArr, selected: false, id: item['id']})

            this.getAll([this.selectedCellIndex[0], this.selectedCellIndex[1]])

          })
          .catch(e => {
            console.log(e);
          });

      // this.objects.push(
      //     {selected: false, values: ['', '', '']},
      // )
    },
    cloneCurrentRow() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];
      const id = this.objects[rowIndex].id

      this.dataService.createClone(id)
          .then(response => {
            console.log(response)
            const item = response.data
            //
            let itemArr = []
            this.columns.forEach(column => {
              const value = item[column.field]
              const {
                formattedValue,
                titleValue,
                cssClasses,
                linkData,
              } = formatField(value, column, item)

              itemArr.push({
                value: value,
                formattedValue: formattedValue,
                titleValue: titleValue,
                cssClasses: cssClasses,
                linkData: linkData,
              })
            });

            this.objects.push({values: itemArr, selected: false, id: item['id']})

            this.getAll([this.selectedCellIndex[0], this.selectedCellIndex[1]])

          })
          .catch(e => {
            console.log(e);
          });

      // this.objects.push(
      //     {selected: false, values: [...this.objects[rowIndex].values]},
      // )
    },
    deleteRows() {
      const countToDelete = this.objects.filter(obj => obj.selected === true).length
      if (countToDelete === 0) return;

      if (confirm('Вы уверены, что хотите удалить выделенные строки?')) {
        const rowIndex = this.selectedCellIndex[0];
        const cellIndex = this.selectedCellIndex[1];
        const selectedIds = this.objects.filter(obj => obj.selected === true).map(obj => obj.id)

        this.dataService.deleteList({ids: selectedIds})
            .then(response => {
              // console.log(response.deleted_count)
              const offset = response.data.deleted_count
              const newRowIndex = rowIndex - offset >= 0 ? rowIndex - offset : 0
              console.log('newRowIndex', newRowIndex, 'rowIndex', rowIndex, 'offset', offset)

              this.getAll([newRowIndex, cellIndex])
            })
            .catch(e => {
              console.log(e);
            });


      }

    },
    getAll(selectedCell = [0, 0]) {
      // filters
      let localFiltersParsed = {}
      this.columns.forEach(column => {
        if (this.localFilters[column.field]) {
          if (column.fieldType === 'text') {
            localFiltersParsed[column.field + '__icontains'] = this.localFilters[column.field]
          } else if (column.fieldType === 'date') {
            if (this.localFilters[column.field].startsWith('>')) {
              localFiltersParsed[column.field + '__gte'] = this.localFilters[column.field].slice(1)
            } else if (this.localFilters[column.field].startsWith('<')) {
              localFiltersParsed[column.field + '__lte'] = this.localFilters[column.field].slice(1)
            } else {
              localFiltersParsed[column.field] = this.localFilters[column.field]
            }
          } else if (column.fieldType === 'priceint' || column.fieldType === 'price') {
            if (this.localFilters[column.field].startsWith('>')) {
              localFiltersParsed[column.field + '__gte'] = this.localFilters[column.field].slice(1)
            } else if (this.localFilters[column.field].startsWith('<')) {
              localFiltersParsed[column.field + '__lte'] = this.localFilters[column.field].slice(1)
            } else {
              localFiltersParsed[column.field] = this.localFilters[column.field]
            }
          } else if (column.fieldType === 'fk') {
            localFiltersParsed[column.fkSaveField + `__${column.fkFieldTitle}__icontains`] = this.localFilters[column.field]
          } else {
            localFiltersParsed[column.field] = this.localFilters[column.field]
          }
        }
      })
      let filters = { ...localFiltersParsed, ...this.tableContext?.baseFilter }
      let sortColumn = this.sortColumn
      let sortDirection = this.sortDirection
      // get data
      this.dataService.getAll(filters, sortColumn, sortDirection, this.page, this.pageSize)
          .then(response => {
            this.totalPages = response.data.total_pages
            this.totalCount = response.data.count
            this.aggregates = response.data.aggregates

            this.objects = response.data.results.map(item => {
              let itemArr = []
              this.columns.forEach(column => {
                const value = item[column.field]
                const {
                  formattedValue,
                  titleValue,
                  cssClasses,
                  linkData,
                } = formatField(value, column, item)

                itemArr.push({
                  value: value,
                  formattedValue: formattedValue,
                  titleValue: titleValue,
                  cssClasses: cssClasses,
                  linkData: linkData,
                })
              });

              return {values: itemArr, selected: false, id: item['id']};
            });
            // console.log(this.objects);
            this.isLoading = false;
            // focus
            setTimeout(() => {
              const controlTable = this.$refs.controlTable;
              if (controlTable) {
                if (this.autofocus) {
                  controlTable.focus();
                }
                this.selectCell(selectedCell[0], selectedCell[1])
              }
            }, 1)

          })
          .catch((e) => {
            console.log(e)
            this.objects = []
            this.isLoading = false;
            this.$store.commit('addToast', {
              title: "Ошибка при получении данных",
              message: `${e}`
            })
          });
    },
    handleFkSelect(option) {
      this.inputFk = option
    }
  },
  mounted() {
    this.getAll()
  },
  watch: {
    objects: {
      handler() {
        // selectAll
        this.selectAll = this.objects.every(item => item.selected);
        // aggregates
        this.footerColumns = this.columns.map(() => {
          return {
            aggregatedValue: '',
            cssClasses: '',
          }
        })
        this.columns.forEach((column, columnIndex) => {
          if (column.aggregate === 'sum') {
            let aggregatedValue = this.aggregates[`${column.field}`]
            // this.objects.forEach((row) => {
            //   aggregatedValue += Number(row.values[columnIndex].value)
            // })
            const fValue = formatField(aggregatedValue, column, {})
            this.footerColumns[columnIndex].aggregatedValue = fValue.formattedValue
            this.footerColumns[columnIndex].cssClasses = fValue.cssClasses
          }
        })
      },
      deep: true
    },
    localFilters: {
      handler() {
        _.debounce(() => {
          this.getAll()
        }, 300)()
      },
      deep: true
    },
    // $props: {
    //   handler() {
    //     console.log('tableContext', this.tableContext)
    //     this.getAll()
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  computed: {
    navPages() {
      if (this.totalPages <= 5) {
        return Array.from({ length: this.totalPages }, (v, i) => i + 1);
      } else {
        return Array.from({ length: Math.min(5, this.totalPages) }, (v, i) => {
          const pageNum = this.page - 2 + i;
          return pageNum > 0 && pageNum <= this.totalPages ? pageNum : null;
        }).filter(n => n);
      }
    }
  }
}
</script>

<style>
.spreadsheet .selected-row td {
  /*background-color: #f8c140;*/
  background-color: var(--bs-warning); /* Использует переменную primary */
  color: white; /* Цвет текста */
}

.helpmate-table:focus .selected-cell, .helpmate-table:focus .selected-cell a {
  background-color: var(--bs-primary); /* изменяемый класс для выделенной ячейки */
  color: white; /* Цвет текста */
}

.helpmate-table-help {
  margin-top: 10px;
  font-size: 10px;
  line-height: 12px;
  color: #292929;
}

.helpmate-cell-number {
  text-align: right;
  font-family: "Lucida Console";
  float: right;
  clear: right;
}

.helpmate-table thead tr th, .helpmate-table tfoot tr th {
  background: var(--bs-light);
}

.helpmate-table th, .helpmate-table td {
  font-size: 10px;
  padding: 3px !important;
}

.helpmate-btn {
  font-size: 10px;
}

.helpmate-btn-hotkey {
  font-size: 8px;
  color: grey;
}

.helpmate-btn-hotkey-light {
  color: #dadada;
}
.helpmate-cell-red {
  color: white;
  background-color: red;
  padding: 2px;
  border-radius: 3px;
  /* display: block; */
}
.helpmate-cell-number.helpmate-cell-red {
  float: right;
  clear: right;
}
.cru-form-control-small {
  font-size: 10px !important;
  padding: 2px !important;
}
</style>
